import React from 'react'
import { ContentGrid, IImage, Image, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import {
  useAddonServicesContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext,
  useSubPageLayoutContext
} from '../../../contexts'
import { IServicePitchCard } from '../../../models'
import {
  DescriptionBlock,
  DetailsDescriptionBlock,
  HighlightedDropdownBlock,
  IDescriptionBlock,
  IDetailsDescriptionBlock,
  IHighlightedDropdownBlock,
  IPlankHouse,
  ISectionBlock,
  PitchCardsBlock,
  PlankHouseBlock,
  SectionBlock
} from '../../../blocks'
import { v4 as uuid4 } from 'uuid'
import { Constants } from '../../../data'
import { IServiceManagementPopup } from '../../../modals'
import { useParams } from 'react-router'
import classNames from 'classnames'
import { IDefaultViewProps, PageV2 } from '../../PageV2'
import './ServiceBasePage.scss'

export interface IServiceBasePageView extends IDefaultViewProps {
  localBrand: string
  banner: IImage
  house?: IPlankHouse
  sections?: ISectionBlock[]
  pitchCards?: {
    items: IServicePitchCard[]
    title?: string
    description?: string
  }
  coreDetails?: IDetailsDescriptionBlock[]
  popup?: IServiceManagementPopup
  dropOrder?: IHighlightedDropdownBlock
  descriptions?: {
    top?: IDescriptionBlock[]
    middle?: IDescriptionBlock[]
    bottom?: IDescriptionBlock[]
  }
}

export interface IServiceBasePage {
  handleData: (config: any) => Promise<any>
}

export const ServiceBasePage = (props: IServiceBasePage) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'service-page'
  const { handleData } = props
  const { setShowInstallationSelector } = useSubPageLayoutContext()
  const { GETTYPED } = useApplicationServicehandlerContext()
  const { serviceId } = useParams()
  const { setInstallation } = useApplicationCoreDataContext()
  const { addonStates } = useAddonServicesContext()

  // ************************************
  // Render Functionality
  // ************************************

  const _renderPitchCards = (config: IServiceBasePageView) => {
    const { pitchCards, localBrand, activeTheme } = config

    if (pitchCards?.items && pitchCards.items.length > 0) {
      return (
        <PitchCardsBlock
          theme={activeTheme}
          brand={localBrand}
          items={pitchCards.items}
          title={pitchCards.title}
          description={pitchCards.description}
        />
      )
    }
  }

  const _renderSections = (config: IServiceBasePageView) => {
    const { sections, localBrand } = config

    if (sections && sections.length > 0) {
      return (
        <StyleGrid
          className={`${classPrefix}__sections`}
          direction='column'
          alignment='top-center'
        >
          {sections?.map((section: any) => {
            return (
              <SectionBlock
                key={uuid4()}
                {...section}
                brand={localBrand}
              />
            )
          })}
        </StyleGrid>
      )
    }
  }

  const _renderDescription = (config: IServiceBasePageView, descriptions?: IDescriptionBlock[]) => {
    const { localBrand, activeTheme, translations } = config

    if (descriptions && descriptions.length > 0) {
      return descriptions.map((data: any) => {
        if (data.blockId !== Constants.services.dynamicInfoDetailsId) {
          return (
            <DescriptionBlock
              {...data}
              key={uuid4()}
              className={`${classPrefix}__description-block`}
              description={data.descriptionText}
              points={data.dataPoints}
              theme={activeTheme}
              brand={localBrand}
              translations={translations}
              useExpandableText={false}
            />
          )
        } else {
          return undefined
        }
      })
    }
  }

  const _renderCoreDetails = (config: IServiceBasePageView) => {
    const { coreDetails, activeBrand, activeTheme } = config

    if (coreDetails && coreDetails.length > 0) {
      return coreDetails.map((details: IDetailsDescriptionBlock) => {
        return (
          <DetailsDescriptionBlock
            {...details}
            key={uuid4()}
            className={`${classPrefix}__details-description-block`}
            theme={activeTheme}
            brand={activeBrand}
          />
        )
      })
    }
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.services.type,
      subPageType: serviceId,
      usesSubPage: true,
      pageClamp: 'stretch'
    },
    dependencies: [serviceId, { addonStates }, { GETTYPED }],
    dependenciesOptional: [{ setInstallation }],
    handleData,
    onDataChange: (config: IServiceBasePageView) => {
      setShowInstallationSelector(false)
    },
    render: (config: IServiceBasePageView) => {
      const { banner, desktopView, activeTheme, activeBrand, house, dropOrder } = config

      return (
        <StyleGrid
          className={classPrefix}
          alignment='top-center'
          direction='column'
        >
          <ContentGrid
            className={`${classPrefix}__introduction`}
            direction='column'
            alignment='top-center'
            tagType='section'
            gap={4}
          >
            {_renderDescription(config, config.descriptions?.top)}

            {banner?.src && (
              <Image
                className={classNames(`${classPrefix}__banner`, {
                  [`${classPrefix}__banner--curve`]: desktopView
                })}
                scalingBy='height'
                fit={'cover'}
                {...banner}
              />
            )}
            {_renderCoreDetails(config)}
          </ContentGrid>
          <StyleGrid
            className={`${classPrefix}__main`}
            direction='column'
            alignment='top-center'
            gap={4}
          >
            <ContentGrid
              className={`${classPrefix}__main__description-container`}
              direction='column'
              alignment='top-center'
              gap={desktopView ? 8 : 4}
              tagType='article'
            >
              {_renderDescription(config, config.descriptions?.middle)}
              {_renderPitchCards(config)}
              {_renderDescription(config, config.descriptions?.bottom)}
            </ContentGrid>
            {_renderSections(config)}
            {house && house?.plankWalls?.length > 0 && (
              <PlankHouseBlock
                className={`${classPrefix}__main__house`}
                theme={activeTheme}
                {...house}
              />
            )}
            {dropOrder && (
              <HighlightedDropdownBlock
                {...dropOrder}
                theme={activeTheme}
                brand={activeBrand}
              />
            )}
          </StyleGrid>
        </StyleGrid>
      )
    }
  })
}