import { format } from 'date-fns'
import { Constants } from '../../../data'
import { ICustomerConsumption, ICustomerConsumptionPoint } from '../../../models'
import {
  AllowFeature,
  createString,
  fetchCustomerConsumptionAndGridTariff,
  getText,
  IsActiveCustomer,
  tNumber
} from '../../../services'
import { IHomePageData } from '../HomePageData'
import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { _getTextPlankPrefabTemplate } from '.'
import { IStatePlank } from '../../../components'

export const GetConsumptionPlanks = (config: IHomePageData): { cost: IStatePlank; use: IStatePlank } | undefined => {
  const { user, translations, services } = config
  const { installation } = user
  const { GET, customerServiceFeature } = services

  const allowUse = AllowFeature(Constants.features.consumptionUse, translations, services.user, customerServiceFeature)
  const allowCost = AllowFeature(
    Constants.features.consumptionCost,
    translations,
    services.user,
    customerServiceFeature
  )
  const currentDate = new Date()
  const firstOfMonth = new Date(new Date().setDate(1))

  if (IsActiveCustomer(installation) && installation && (allowUse || allowCost)) {
    const consumption = fetchCustomerConsumptionAndGridTariff(
      installation?.meterId,
      {
        resolution: 'day',
        to: currentDate,
        from: firstOfMonth
      },
      GET
    )

    const use = {
      promise: fetchConsumptionPlank(config, consumption)
    } as IStatePlank

    const cost = {
      promise: fetchConsumptionCostPlank(config, consumption)
    } as IStatePlank

    return { cost, use }
  }
}

const fetchConsumptionPlank = async (config: IHomePageData, consumption: Promise<ICustomerConsumption>) => {
  const { activeBrand, activeTheme, translations, user } = config
  const { installation } = user

  let consumptionEndDate = new Date()
  const consumptionStartDate = new Date(new Date().setDate(1))
  let consumptionDesc: string | undefined = undefined
  let consumptionUseTotal: number = 0
  let consumptionValue: string | undefined = undefined
  let validFromDate: Date | undefined = undefined
  const currentDate: Date = new Date()
  const yearlyConsumptionEstimate: number = installation?.estimatedYearlyConsumption ?? 0

  let hasConsumption = false

  if (installation) {
    const from = new Date(installation?.validFrom)

    if (from > currentDate) {
      validFromDate = from
    }
  }

  return Promise.resolve(consumption).then((con: ICustomerConsumption) => {
    if (con.consumptionPoints && con.consumptionPoints.length > 0) {
      con.consumptionPoints.forEach((cons: ICustomerConsumptionPoint) => {
        if (cons.energy && !cons.energy.isEstimated) {
          consumptionUseTotal += cons.energy.amount
          consumptionEndDate = new Date(cons.startTime)
          //if null/undefined from backend, "empty" data for period is constructed. Check for real data before
          //setting flag hasConsumption
          if (!cons.isConstructedEmptyItem) hasConsumption = true
        }
      })
    }

    if (validFromDate) {
      consumptionDesc = createString(getText('consumptionAvilableAt', translations), {
        date: format(validFromDate, 'dd.MM.yyy')
      })
      consumptionValue = getText('consumptionAvailableValue', translations)
    } else {
      consumptionDesc = `${format(consumptionStartDate, 'dd.MM')} → ${format(consumptionEndDate, 'dd.MM')}`
      consumptionValue = createString(getText('plankConsumptionUseDesc', translations), {
        amount: !hasConsumption && consumptionUseTotal === 0 ? '--' : tNumber(consumptionUseTotal, 'no-NO', 0, 0)
      })
    }

    return _getTextPlankPrefabTemplate({
      theme: activeTheme,
      brand: activeBrand,
      title: getText('plankConsumptionUseTitle', translations),
      description: consumptionDesc,
      rightTitle: consumptionValue,
      link: Constants.paths.powerUsagePage,
      iconRight: IconType.ChevronRight,
      disabled: !!validFromDate && yearlyConsumptionEstimate === 0
    })
  })
}

const fetchConsumptionCostPlank = async (config: IHomePageData, consumption: Promise<ICustomerConsumption>) => {
  const { activeTheme, activeBrand, translations, user } = config
  const { installation } = user

  const costSupportEnabled = localStorage.getItem('showCostSupport')
  const currentDate: Date = new Date()
  let consumptionDesc: string | undefined = undefined
  let consumptionValue: string | undefined = undefined
  let consumptionCostTotal: number = 0
  let validFromDate: Date | undefined = undefined
  let costStartDate = new Date()
  const costEndDate = new Date(new Date().setDate(1))

  if (installation) {
    const from = new Date(installation?.validFrom)

    if (from > currentDate) {
      validFromDate = from
    }
  }

  return Promise.resolve(consumption).then((cons: ICustomerConsumption) => {
    consumptionCostTotal += cons.gridTariff ?? 0

    if (cons.consumptionPoints && cons.consumptionPoints.length > 0) {
      cons?.consumptionPoints.forEach((con: ICustomerConsumptionPoint) => {
        if (con.cost) {
          if (costSupportEnabled && con.costIncludingSupport) {
            if (!con.costIncludingSupport.isEstimated) {
              consumptionCostTotal += con.costIncludingSupport.amount
              costStartDate = new Date(con.startTime)
            }
          } else {
            if (!con.cost.isEstimated) {
              consumptionCostTotal += con.cost.amount
              costStartDate = new Date(con.startTime)
            }
          }
        }
      })

      if (validFromDate) {
        consumptionDesc = createString(getText('consumptionAvilableAt', translations), {
          date: format(validFromDate, 'dd.MM.yyy')
        })
      } else {
        consumptionDesc = `${format(costEndDate, 'dd.MM')} → ${format(costStartDate, 'dd.MM')}`
        consumptionValue = createString(getText('plankConsumptionCostDesc', translations), {
          amount: tNumber(consumptionCostTotal, 'no-NO', 0, 0)
        })
      }
    }

    return _getTextPlankPrefabTemplate({
      theme: activeTheme,
      brand: activeBrand,
      title: getText('plankConsumptionCostTitle', translations),
      description: consumptionDesc,
      rightTitle: consumptionValue,
      link: Constants.paths.powerCostPage,
      iconRight: IconType.ChevronRight,
      disabled: !!validFromDate
    })
  })
}