import React, { useEffect, useMemo, useState } from 'react'
import { ServiceBasePage } from './ServiceBasePage'
import {
  useApplicationAddonServicesContext,
  useApplicationCoreDataContext,
  useApplicationDefaultContext
} from '../../../contexts'
import {
  getBanner,
  getCoreDetails,
  getDescriptions,
  getDropOrder,
  getLocalBrand,
  getPitchCards,
  getSections
} from './ServiceBasePageData'
import { getTrumfCustomerInstallationStatusData, getTrumfHouse } from './Datahandling/UniqueServices'
import {
  IAddonData,
  IServicePopupBlock,
  IServicePopupCheckbox,
  OnClickServiceOrderAction,
  OnClickServiceOrderActionEnum,
  ServiceOrderSteps,
  ServiceOrderStepsEnum,
  ServiceStatusEnum
} from '../../../models'
import { capitalizeFirstLetter, fetchTrumfOrderHandling, getServiceStatus, getText } from '../../../services'
import { ActionButton } from '../../../components/Buttons/ActionButton/ActionButton'
import { getServiceDescriptionForStatus, initialSelectOption } from '../../../modals/ServiceManagementPopup/utils'
import { useSearchParams } from 'react-router-dom'
import { getTranslations } from '../../pageUtils'
import { Constants } from '../../../data'
import { useParams } from 'react-router'
import { PopupContent } from '../../../modals/ServiceManagementPopup/components/PopupContent'
import { v4 as uuid4 } from 'uuid'
import _ from 'lodash'
import { PopupCard } from '../../../modals'
import { NotificationBlock } from '../../../blocks/NotificationBlock/NotificationBlock'
import { Text } from '@fjordkraft/fjordkraft.component.library'
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../../Prefabs'

import './TrumfServicesPage.scss'

export const TrumfServicePage = () => {
  //contextdata
  const { defaultProps } = useApplicationDefaultContext()
  const {
    epiChildren,
    activeTheme: theme,
    activeBrand: brand,
    translations,
    desktopView,
    user,
    services
  } = defaultProps
  const { GET, POST } = services
  const { addonStates, updateAddonStates } = useApplicationAddonServicesContext()
  const { setTranslation } = useApplicationCoreDataContext()

  //params
  const { serviceId } = useParams()
  const [queryParams, setQueryParams] = useSearchParams()
  const queryParamState = queryParams?.get('orderStatus') as ServiceOrderSteps | undefined

  //popupStates
  const [checkboxStates, setCheckboxStates] = useState<IServicePopupCheckbox[]>()
  const [isEditing, setIsEditing] = useState<boolean>(queryParamState ? true : false)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const classPrefix = 'trumf-services-page'

  //popupsteps
  const getNextStep = (currentStep: ServiceOrderSteps, steps: ServiceOrderSteps[]) => {
    const currentIndex = steps.indexOf(currentStep)
    const hasNextStep = steps.length - 1 > currentIndex
    return hasNextStep ? steps[currentIndex + 1] : null
  }

  const getPreviousStep = (currentStep: ServiceOrderSteps, steps: ServiceOrderSteps[]) => {
    const currentIndex = steps.indexOf(currentStep)
    const hasPreviousStep = currentIndex > 0
    return hasPreviousStep ? steps[currentIndex - 1] : null
  }

  const updateStep = (stepChange: ServiceOrderSteps) => {
    const nextStep = getNextStep(stepChange, trumfStepsOrder)
    const previousStep = getPreviousStep(stepChange, trumfStepsOrder)
    setCurrentStep(stepChange)
    setNextStep(nextStep)
    setPreviousStep(previousStep)
  }

  const trumfStepsOrder = [ServiceOrderStepsEnum.DEFAULT]
  const [currentStep, setCurrentStep] = useState<ServiceOrderSteps>(queryParamState ?? trumfStepsOrder[0])
  const [nextStep, setNextStep] = useState<ServiceOrderSteps | null>(getNextStep(currentStep, trumfStepsOrder))
  const [previousStep, setPreviousStep] = useState<ServiceOrderSteps | null>(null)

  const [isOrder, setIsOrder] = useState<boolean>(queryParamState ? true : false)

  const status = useMemo(() => {
    if (isOrder) return ServiceStatusEnum.INACTIVE
    if (translations) return getServiceStatus(translations, addonStates, user.installation)
  }, [translations, addonStates, user.installation, isOrder])

  const [selectedOption, setSelectedOption] = useState<OnClickServiceOrderAction | undefined>(
    status ? initialSelectOption[status] : undefined
  )

  const installationStatus = getTrumfCustomerInstallationStatusData(user, translations, addonStates, inProgress)

  useEffect(() => {
    const fetchedTranslations = getTranslations(
      epiChildren,
      Constants.epiServerPageNames.services.type,
      translations,
      serviceId
    )
    setTranslation(fetchedTranslations)
  }, [defaultProps])

  useEffect(() => status && setSelectedOption(initialSelectOption[status]), [status])

  const onEditTrumf = () => {
    setIsEditing(!isEditing)
  }

  const onConfirmClickTrumf = async () => {
    setCurrentStep(ServiceOrderStepsEnum.LOADING)
    const result = await fetchTrumfOrderHandling(selectedOption as OnClickServiceOrderAction, GET, POST)
    setCurrentStep(result)
    setInProgress(result === ServiceOrderStepsEnum.IN_PROGRESS)
  }

  const serviceActionData = useMemo(() => {
    if (status && translations) {
      let statusForSelection = status
      //Covers case with one installation active and one inactive, recalculates texts based on chosen option
      if (selectedOption === OnClickServiceOrderActionEnum.CANCEL) statusForSelection = ServiceStatusEnum.ACTIVE
      if (selectedOption === OnClickServiceOrderActionEnum.ORDER) statusForSelection = ServiceStatusEnum.INACTIVE
      const serviceStatusDescription = getServiceDescriptionForStatus(
        statusForSelection,
        translations.serviceDescriptions
      )
      if (serviceStatusDescription?.servicePopupBlock) {
        const { servicePopupBlock } = serviceStatusDescription
        const checkboxes = servicePopupBlock?.checkboxes?.map(e => ({ ...e, id: uuid4(), alreadyChecked: false }))

        const servicePopupBlockData: IServicePopupBlock = {
          texts: servicePopupBlock.texts,
          dataPoints: [],
          selectedExtraProductDesc: '',
          description: servicePopupBlock?.description,
          links: servicePopupBlock?.links,
          checkboxes: undefined,
          orderResult: servicePopupBlock?.orderResult,
          orderMethodType: servicePopupBlock?.orderMethodType
        }

        //either if checkboxes has not been set, or if current state does not have checkboxes: update
        if (!checkboxStates || !checkboxes) setCheckboxStates(checkboxes)
        return servicePopupBlockData
      }
    }
  }, [status, translations, selectedOption])

  const getPageData = async (config: any) => {
    const { translations, desktopView, activeTheme, relationship, user, services } = config

    return {
      sub: {
        title: getText('pageTitle', config.translations),
        back: {
          text: getText('back', config.translations),
          link: `${Constants.paths.servicesPage}`
        }
      },
      localBrand: getLocalBrand(translations), //service-brand //brukes kun til trumf, forutsigbar/steddi
      banner: getBanner(translations),
      house: await getTrumfHouse({
        desktopView,
        activeTheme,
        relationship,
        user,
        services,
        addonStates: addonStates ?? ([] as IAddonData[]),
        inProgress: inProgress,
        translations,
        onClickService: onEditTrumf
      }),
      sections: await getSections(translations, user, services, addonStates),
      pitchCards: await getPitchCards(translations, user, services, addonStates),
      coreDetails: await getCoreDetails(
        config.activeTheme,
        config.activeBrand,
        config.translations,
        config.user,
        config.services,
        config.addonStates,
        config.setInstallation
      ),
      dropOrder: await getDropOrder(config),
      descriptions: await getDescriptions(config)
    } as any // IServiceBasePageView
  }

  const allChecksChecked = useMemo(() => !!checkboxStates?.find(checkbox => !checkbox.alreadyChecked), [checkboxStates])

  const popupTitleKey = {
    [ServiceOrderStepsEnum.FAILURE]: `failureResultTitle${capitalizeFirstLetter(selectedOption)}`,
    [ServiceOrderStepsEnum.ERROR]: `failureResultTitle${capitalizeFirstLetter(selectedOption)}`,
    [ServiceOrderStepsEnum.IN_PROGRESS]: 'waitingResultTitle',
    [ServiceOrderStepsEnum.SUCCESS]: 'successResultTitle',
    [ServiceOrderStepsEnum.REDIRECT_SUCCESS]: 'successResultTitleOrder',
    [ServiceOrderStepsEnum.SELECT]: 'confirmTitle',
    [ServiceOrderStepsEnum.DEFAULT]: `title${capitalizeFirstLetter(selectedOption)}`
  }

  const getPopupTitle = useMemo(() => {
    const textKey = _.get(popupTitleKey, `${currentStep}`, popupTitleKey[ServiceOrderStepsEnum.DEFAULT])
    return getText(textKey, serviceActionData, 'texts')
  }, [currentStep, serviceActionData])

  const onClose = () => {
    setQueryParams(undefined)
    setIsEditing(false)
    setIsOrder(false)
    setInProgress(currentStep === ServiceOrderStepsEnum.SUCCESS)
    updateAddonStates()
    updateStep(trumfStepsOrder[0])
  }

  const navigationButtons = {
    ...(trumfStepsOrder.includes(currentStep as ServiceOrderStepsEnum) && {
      primaryAction: {
        ...(nextStep
          ? {
              onClick: () => updateStep(nextStep),
              text: getText('readMore', translations)
            }
          : {
              disabled: allChecksChecked,
              onClick: onConfirmClickTrumf,
              text: getText('readMore', translations)
            })
      },
      secondaryAction: {
        ...(previousStep
          ? {
              onClick: () => updateStep(previousStep),
              text: getText('goBack', translations)
            }
          : {
              onClick: onClose,
              text: getText('closePopupButton', translations)
            })
      }
    })
  }

  return (
    <>
      <ServiceBasePage handleData={getPageData} />
      {installationStatus?.activeAndInactiveInstallations && (
        <NotificationBlock
          className={`${classPrefix}__notifications`}
          notifications={[
            {
              type: 'Note',
              title: getText('installationNotActiveTitle', translations),
              children: [
                <Text
                  {...paragraphTextPrefab()}
                  align={'align-center'}
                  key={uuid4()}
                >
                  <ActionButton
                    template={MS_ButtonTemplate(theme, 'link')}
                    action={{
                      onClick: () => {
                        setIsOrder(true)
                        setIsEditing(true)
                      },
                      text: getText('installationNotActiveText', translations),
                      disabled: false
                    }}
                    padding={'small'}
                    iconPlacement='Right'
                  />
                </Text>
              ]
            }
          ]}
        />
      )}
      {serviceActionData && selectedOption && isEditing && (
        <PopupCard
          theme={theme}
          brand={brand}
          contentTitle={getPopupTitle}
          contentTitleAlignment={
            currentStep === ServiceOrderStepsEnum.SELECT || currentStep === ServiceOrderStepsEnum.DEFAULT
              ? 'align-left'
              : 'align-center'
          }
          contentGap={4}
          onClose={onClose}
          desktopView={desktopView}
          {...navigationButtons}
        >
          <PopupContent
            currentStep={currentStep}
            classPrefix={'service-stage-popup'}
            selectedOption={selectedOption}
            isTrumf={true}
            theme={theme}
            brand={brand}
            checkboxStates={checkboxStates}
            setCheckboxStates={setCheckboxStates}
            productDefinitionId={translations.productDefinitionId}
            data={{
              dataPoints: serviceActionData?.dataPoints,
              texts: serviceActionData?.texts,
              house: serviceActionData?.house,
              selectedExtraProductDesc: serviceActionData?.selectedExtraProductDesc,
              description: serviceActionData?.description,
              links: serviceActionData?.links
            }}
            onClickSelectOption={val => setSelectedOption(val?.value)}
          />
        </PopupCard>
      )}
    </>
  )
}
