import React, { useEffect, useMemo, useState } from 'react'
import { ICustomer, IGuestRelationship } from '../../../models'
import { ApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { isGuestCheck } from '../../../services'
import { useAuth } from 'react-oidc-context'
import { chosenHostAtStartup, mainUserAtStartup } from '../../../authentication/AuthenticationGuard'

export const ApplicationGuestsAndHostsWrapper = (props: any) => {
  // ************************************
  // Lifecycle
  // ************************************

  const { user } = useAuth()
  const [isGuest, setIsGuest] = useState<boolean>(false)
  const [hostIdForCustomerDataRequests, setHostIdForCustomerDataRequests] = useState<string>()
  const [guests, setGuests] = useState<IGuestRelationship[]>()
  const [hosts, setHosts] = useState<IGuestRelationship[]>()
  //Initialize with session storage values if they exist
  const [chosenHost, setChosenHost] = useState<IGuestRelationship | undefined>(chosenHostAtStartup)
  const [mainUser, setMainUser] = useState<ICustomer | undefined>(mainUserAtStartup)

  const context = useMemo(() => {
    return {
      isGuest,
      setIsGuest,
      hostIdForCustomerDataRequests,
      setHostIdForCustomerDataRequests,
      guests,
      setGuests,
      hosts,
      setHosts,
      chosenHost,
      setChosenHost,
      mainUser,
      setMainUser
    }
  }, [hostIdForCustomerDataRequests, guests, hosts, chosenHost, mainUser, isGuest])

  useEffect(() => {
    setIsGuest(
      isGuestCheck({
        chosenHost,
        hosts,
        mainUser
      })
    )
  }, [hostIdForCustomerDataRequests, chosenHost, mainUser])

  useEffect(() => {
    if (chosenHost) {
      sessionStorage.setItem(`chosenHost_${user?.profile.sub}`, JSON.stringify(chosenHost))
      sessionStorage.setItem(`mainUser_${user?.profile.sub}`, JSON.stringify(mainUser))
    } else {
      sessionStorage.removeItem(`chosenHost_${user?.profile.sub}`)
      sessionStorage.removeItem(`mainUser_${user?.profile.sub}`)
    }
    setHostIdForCustomerDataRequests(chosenHost?.customerId)
  }, [chosenHost])

  // ************************************
  // Render
  // ************************************

  return (
    <ApplicationGuestsAndHostsContext.Provider value={context}>
      {props.children}
    </ApplicationGuestsAndHostsContext.Provider>
  )
}