import React, { useEffect, useState } from 'react'
import {
  CallState,
  CheckInput,
  ContentGrid,
  getCounterTheme,
  IComponent,
  StyleGrid,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import { PopupCard } from '../PopupCard/PopupCard'
import { MSCheckInputTemplate, paragraphTextPrefab } from '../../Prefabs'
import { MSRichText } from '../../components'
import { capitalizeFirstLetter, getText, IResponse, logger } from '../../services'
import { ConsentStateEnum, IHomePage, MarketingConsentType } from '../../models'
import { CustomerInfoEditor, ICustomerEditorInformation } from './CustomerInfoEditor/CustomerInfoEditor'
import {
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext,
  useConsentContext
} from '../../contexts'
import './CustomerInfoModal.scss'
import { callStateToast } from '../../components/Toastify/Toastify'

export interface ICustomerInfoModalData extends IComponent {
  translations: IHomePage
  consent: boolean
}

export interface ICustomerInfoModal extends ICustomerInfoModalData {
  closeModal: (updateCustomer: boolean, response?: IResponse) => void
}

export const CustomerInfoModal = (props: ICustomerInfoModal) => {
  // ************************************
  // Properties
  // ************************************

  const { closeModal, translations, consent } = props
  const classPrefix = 'userData-info-modal'
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const { userData } = useApplicationCoreDataContext()
  const { PUT } = useApplicationServicehandlerContext()
  const { useNewConsents, PUT_CONSENTS } = useConsentContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [allowSubmit, setAllowSubmit] = useState<'yes' | 'no'>('no')
  const [updatedInfo, setUpdatedInfo] = useState<ICustomerEditorInformation>()
  const [toggledConsent, setToggledConsent] = useState<boolean>(false)
  const [updateState, setUpdateState] = useState<CallState>('idle')

  useEffect(() => {
    if (userData?.contactInformation?.email) {
      setUpdatedInfo({
        email: userData?.contactInformation?.email ?? ''
      })
      if (_validEmail(updatedInfo?.email)) setAllowSubmit('yes')
    } else {
      setUpdatedInfo({ email: '' })
    }
  }, [userData])

  // ************************************
  // Validator
  // ************************************

  const _validEmail = (value?: string) => {
    if (!value) {
      return true
    }

    let validatorString = String(value)
      .toLocaleLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    return validatorString !== null
  }

  // ************************************
  // Helpers
  // ************************************

  const _getTitle = () => {
    return userData?.contactInformation?.email
      ? getText('customerInfoModalTitle', translations)
      : getText('customerInfoModalTitleMissingEmail', translations)
  }

  const _getDescription = () => {
    return userData?.contactInformation?.email
      ? getText('customerInfoModalDescriptionText', translations)
      : getText('customerInfoModalDescriptionTextMissingEmail', translations)
  }

  // ************************************
  // Async handling
  // ************************************

  const _handleCustomerSubmitDetailInfo = async () => {
    if (_validEmail(updatedInfo?.email)) {
      // If userData has toggled consent in checkbox, we put consent and consent channels
      if (toggledConsent) {
        PUT_CONSENTS([{ value: ConsentStateEnum.ACCEPTED, consentName: MarketingConsentType.Legacy1 }], translations)
      }

      if (updatedInfo?.email && updatedInfo.email.length > 0) {
        setUpdateState('pending')
        let resp = await PUT(`Customers/email/update`, updatedInfo.email)

        callStateToast({
          text: getText(`contactInfo${capitalizeFirstLetter(resp.callState)}`, translations),
          callState: resp.callState
        })

        closeModal(true, resp)
      } else {
        logger('updatedInfo was not found', 'warn')
      }

      setUpdateState('idle')
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <PopupCard
      className={`${classPrefix}__popup-card-container`}
      contentTitle={_getTitle()}
      contentGap={4}
      desktopView={desktopView}
      onClose={() => {
        closeModal(false)
      }}
      secondaryAction={{
        text: getText('cancel', translations),
        onClick: () => {
          closeModal(false)
        }
      }}
      primaryAction={{
        disabled: allowSubmit === 'no' && updateState !== 'pending',
        text: getText('save', translations),
        onClick: () => {
          _handleCustomerSubmitDetailInfo()
        }
      }}
    >
      <Text
        className={`${classPrefix}__top-text`}
        {...paragraphTextPrefab()}
      >
        {_getDescription()}
      </Text>
      <CustomerInfoEditor
        className={`${classPrefix}__editor`}
        brand={activeBrand}
        theme={activeTheme}
        customerInformation={{
          email: userData?.contactInformation?.email ?? ''
        }}
        translation={translations}
        onChange={(value: ICustomerEditorInformation, valid: boolean) => {
          setUpdatedInfo(value)
          setAllowSubmit(valid ? 'yes' : 'no')
        }}
      />
      {!consent && !useNewConsents && (
        <ContentGrid
          className={`${classPrefix}__check-input`}
          direction='row'
          alignment='top-left'
          boxSizing='border-box'
          gap={2}
          wrap={false}
          tagType='section'
        >
          <StyleGrid
            className={`${classPrefix}__check-input__checkbox`}
            alignment={'center-left'}
            direction={'row'}
            scaling={1}
          >
            <CheckInput
              template={MSCheckInputTemplate(activeTheme, 'checkbox')}
              theme={activeTheme}
              brand={activeBrand}
              type={'checkbox'}
              active={toggledConsent}
              onChange={() => {
                setToggledConsent(!toggledConsent)
              }}
            />
          </StyleGrid>
          <StyleGrid
            alignment={'top-left'}
            direction={'column'}
            gap={2}
          >
            <MSRichText
              brand={activeBrand}
              theme={getCounterTheme(activeTheme)}
              text={getText('customerInfoModalCheckmarkHtmlText', translations)}
            />
          </StyleGrid>
        </ContentGrid>
      )}
    </PopupCard>
  )
}